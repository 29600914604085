<template>
  <div class="notification-list d-flex flex-column overflow-hidden">
    <BaseHeader
      :title="$t('推播通知')"
      :is-title-bold="!$device.isMobile"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
      :left-arrow="true"
    ></BaseHeader>
    <div class="notification-wrap scroll-bar position-relative">
      <LoadingWrapper v-if="isLoading" />

      <EmptyPlaceholder
        v-else-if="notifications.length === 0 && !isLoading"
        icon="Bell"
        :text="$t('目前沒有推播通知')"
      />
      <div v-else class="notification-container">
        <NotificationCard
          v-for="notification in notifications"
          :key="notification.createdAt"
          :notification="notification"
        />
        <p class="no-more-text text-12 text-center my-4">
          {{ $t('已經滑到底了') }}
        </p>
      </div>
    </div>
    <ModalNewRulesGuide
      :show="isGuideShow"
      :img="newRulesImg"
      :has-btn="true"
      :title="$t('【推播通知】功能說明')"
      @open="openPdf"
      @close="closeGuide"
    ></ModalNewRulesGuide>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import NotificationCard from '@/components/Notification/NotificationCard.vue';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import ModalNewRulesGuide from '@/components/Modal/ModalNewRulesGuide.vue';
import newRulesImg from '@/assets/img/guide/notification-guide.png';
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import {Browser} from '@capacitor/browser';

export default {
  components: {
    LoadingWrapper,
    BaseHeader,
    NotificationCard,
    EmptyPlaceholder,
    ModalNewRulesGuide,
  },
  data() {
    return {
      isLoading: false,
      isGuideShow: false,
      newRulesImg,
    };
  },
  computed: {
    notifications() {
      return this.$store.getters['notification/notifications'];
    },
    hasUnreadNotifications() {
      return this.$store.getters['notification/hasUnread'];
    },
  },
  created() {
    this.getNotifications();
    if (!JSON.parse(getLocalData('has-notifications-guide'))) {
      this.isGuideShow = true;
    }
  },
  beforeDestroy() {
    if (this.hasUnreadNotifications) {
      this.$store.dispatch('notification/updateNotifications');
    }
  },
  methods: {
    async getNotifications() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('notification/getNotifications', 50);
      } catch (error) {
        this.$Message.error(this.$t('取得推播通知錯誤'));
      } finally {
        this.isLoading = false;
      }
    },
    closeGuide() {
      this.isGuideShow = false;
      saveLocalData('has-notifications-guide', JSON.stringify(true));
    },
    openPdf(type) {
      let url = '';
      switch (type) {
        case 'ios':
          url =
            'https://drive.google.com/file/d/1Ws2SpVj0pJf152Gxe1TEFkdP9OfBejL_/view';
          break;
        case 'android':
          url =
            'https://drive.google.com/file/d/1yJouTAlEEg7OB-NulWAcMh-PLmqLtx2u/view';
          break;
      }
      Browser.open({
        url,
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-list {
  .notification-wrap {
    overflow: overlay;
    height: calc(100vh - 56px);
  }
  .no-more-text {
    color: $font-grayscale-2;
  }
}

@media screen and (min-width: 768px) {
  .notification-list {
    .notification-wrap {
      height: calc(100vh - 80px);
    }
    .notification-container {
      max-width: 720px;
      margin: 0 auto;
      padding: 40px 16px 0px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .notification-list {
    .notification-container {
      max-width: 1140px;
    }
  }
}
</style>
