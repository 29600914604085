<template>
  <div
    class="notification-card d-flex align-items-center justify-content-between cursor-pointer"
    :class="{'is-read': notification.isRead}"
    @click="onClickNotification"
  >
    <div class="card-wrap">
      <div class="title text-18 font-weight-bold">{{ notification.title }}</div>
      <div class="content text-14 my-1">
        {{ notification.message }}
      </div>
      <div class="time text-12">
        {{ $day(notification.createdAt).format('YYYY-MM-DD') }}
      </div>
    </div>
    <i
      v-if="isClickAble"
      class="icon-Chevron-right text-primary text-32 d-block"
    ></i>
  </div>
</template>

<script>
import notificationHandler from '@/lib/base/notificationHandler.js';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    isClickAble() {
      return (
        this.notification?.webUrl ||
        (this.notification?.appRouterName &&
          !['', 'notification'].includes(this.notification.appRouterName))
      );
    },
  },

  methods: {
    onClickNotification() {
      if (!this.isClickAble) {
        return;
      }
      const info = {
        name: this.notification.appRouterName,
        url: this.notification.webUrl,
        coursePlan: this.notification.coursePlan,
        params: this.notification.appRouterParams,
      };

      notificationHandler(info);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  border-bottom: 1px solid $line-2;
  background: $white;
  padding: 16px;

  &.is-read {
    background: $grayscale-gray;
  }
  .card-wrap {
    width: 262px;
  }
  .title {
    color: $font-grayscale-1;
    line-height: 30px;
  }
  .content {
    color: $font-grayscale-1;
    line-height: 22px;
  }
  .time {
    color: $font-grayscale-2;
    line-height: 20px;
  }
}

@media screen and (min-width: 768px) {
  .notification-card {
    border-bottom: none;
    border-radius: 10px;
    box-shadow: 0px 2px 7px 0px rgba(199, 199, 199, 0.3);
    padding: 12px 32px 12px 16px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .card-wrap {
      width: 612px;
    }
  }
}
</style>
